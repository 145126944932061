<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 15:19:14
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-28 14:39:20
-->
<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol">
        <br/>
        <!-- 类型名称 -->
        <a-form-model-item label="类型名称" prop="businessTypeName">
            <a-input placeholder="请输入" v-model="form.businessTypeName"/>
        </a-form-model-item>
        <!-- 类型编号 -->
        <a-form-model-item label="类型编号" prop="businessTypeCode">
            <a-input placeholder="请输入" :disabled="form.id!==''" v-model="form.businessTypeCode"/>
        </a-form-model-item>
        <a-form-model-item label="业务特性" prop="goBusinessProperties">
          <!-- <a-select mode="tags" v-model="form.goBusinessProperties" style="width: 100%" :token-separators="[',']">
          </a-select> -->
          <a-select
            show-search
            mode="multiple"
            placeholder="请选择"
            :value="selectedItems"
            style="width: 100%"
            @change="handleChange"
            option-filter-prop="children"
          >
            <a-select-option v-for="item in filteredOptions" :key="item.id" :value="item.id">
              {{ item.propertyName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 栏目描述 -->
        <a-form-item label="备注">
            <a-textarea v-model="form.memo" placeholder="请输入备注" :rows="4"/>
        </a-form-item>
        <!-- 底部按钮 -->
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
            <a-button type="primary" @click.prevent="onSubmit">提交</a-button>
            <a-button style="margin-left: 10px" @click="resetFields">取消</a-button>
        </a-form-item>
        <br/>
    </a-form-model>
</template>

<script>
export default {
  data () {
    return {
      filteredOptions: [],
      selectedItems: [],

      iseditorAdd: '',
      value: undefined,
      treeData: [],
      options: [
        {
          value: 'jack',
          label: 'Jack'
        },
        {
          value: 'lucy',
          label: 'Lucy'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写栏目名称', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请填写排序', trigger: 'blur' }
        ]
      },
      form: {
        id: '',
        idGoBusinessPropertys: [],
        bussinessTypeName: '',
        sort: undefined,
        goBusinessProperties: [],
        memo: ''
      },
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    }
  },
  watch: {
    value (value) {
      console.log(value)
    }
  },
  created () {
    this.queryTreeData()
  },
  methods: {
    handleChange (selectedItems) {
      this.selectedItems = selectedItems
    },
    resetForm () {
      this.form = {
        idGoBusinessPropertys: [],
        bussinessTypeName: '',
        sort: undefined,
        goBusinessProperties: [],
        memo: ''
      }
    },
    async init ({ editorAdd, id, parentId }) {
      // this.queryTreeData()
      this.resetForm()
      this.iseditorAdd = editorAdd || ''
      if (editorAdd === 'add') {
        this.form.id = ''
        this.form.parentId = id
        this.selectedItems = []
      } else if (editorAdd === 'edit') {
        const res = await this.$store.dispatch('http', {
          api: 'getBusinessTypeInfo',
          query: { id: id }
        })
        console.log(res, 'formINfo')
        if (res) {
          this.form = { ...res }
          this.selectedItems = []
          if (res && res.businessProperty) {
            res.businessProperty.forEach((item) => {
              this.selectedItems.push(item.id)
            })
          } else {
            this.selectedItems = []
          }
        }
      }
      this.getPropertyList()
    },
    async getPropertyList () {
      const res = await this.$store.dispatch('http', {
        api: 'businessPropertiesList'
      })
      console.log(res, 'businessPropertiesList')
      this.filteredOptions = res
    },
    onSubmit () {
      // this.submitForm()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async submitForm () {
      let apiStr = ''
      apiStr = this.iseditorAdd === 'add' ? 'businessTypeListAdd' : this.iseditorAdd === 'edit' ? 'businessTypeListEdit' : ''
      console.log(apiStr)
      console.log(this.form)
      this.form.idGoBusinessPropertys = this.selectedItems
      const res = await this.$store.dispatch('http', {
        api: apiStr,
        params: this.form
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
        this.$emit('ok')
      }
    },
    async queryTreeData () {
      const res = await this.$store.dispatch('http', {
        api: 'navagationTree',
        params: { navigationType: 'cms' }
      })
      console.log(res)
      this.treeData = res
    },
    resetFields () {
      console.log('resetFields')
      this.$emit('ok')
    }
  }
}
</script>

<style>

</style>
